body,
html {
    margin: 0;
    height: 100%;
    width: 100%;
    padding: 0;
    overflow: hidden;
}

#root {
    height: 100%;
    max-height: 100%;
    width: 100%;
    max-width: 100%;
}

iframe {
    pointer-events: "none";
}
